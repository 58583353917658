function initVideos() {
  const videos = document.querySelectorAll('[data-video-player]:not([data-initialized])');

  videos.forEach((video) => {
    video.dataset.initialized = 'true';
    let player;

    const onPlayerReady = (event) => {
      // event.target.playVideo();
      // player.mute();
    };

    const onPlayerStateChange = () => {

    };

    window.YT.ready(() => {
      player = new window.YT.Player(video.id, {
        playerVars: {
          loop: 0,
          iv_load_policy: 3,
          autoplay: 0,
          controls: 0,
          rel: 0,
          color: 'white',
        },
        videoId: video.innerHTML,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    });


    // const videoLink = document.querySelector('[data-video-link]');
    // videoLink.addEventListener('click', () => {
    //   player.pauseVideo();
    // });
    //
    // fsLightboxInstances['lightbox'].props.onClose = () => player.playVideo();
  });
}

document.addEventListener('DOMContentLoaded', () => {
  initVideos();
});