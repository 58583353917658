import LiveEvent from "../../components/live/live";
import zenscroll from "zenscroll";
zenscroll.setup(null, 0);

new LiveEvent('click', '[data-scroll-link], .scroll-link', function scrollLink(e) {
  e.preventDefault();
  let target = null;
  if (this.dataset.selector) {
    target = document.querySelector(this.dataset.selector);
  } else {
    target = document.querySelector(this.getAttribute('href'));
  }
  let offset = this.dataset.offset || 0;
  if (target) {
    let viewportOffset = target.getBoundingClientRect();
    let top = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 200);
    zenscroll.toY(top + viewportOffset.top + offset, 500);
  }

  const group = this.dataset.scrollLink;
  if (group) {
    const allElements = document.querySelectorAll(`[data-scroll-link="${group}"]`);
    allElements.forEach((link) => {
      if (link !== this) {
        link.classList.remove('_active');
      }
    });
    this.classList.add('_active');
  }
});

new LiveEvent('click', '[data-toggle-link], .toggle-link', function toggleLink(e) {
  e.preventDefault();
  let target = document.querySelector(this.dataset.selector);
  let selfToggle = this.dataset.selfToggle;
  target.classList.toggle(this.dataset.toggle);
  if (selfToggle){
    this.classList.toggle(selfToggle);
  }
});