import Jax from '../../components/jax/jax';
import _ from "lodash";

function initFieldTogglers(form) {
  const togglers = form.querySelectorAll('[data-field-toggler]');
  togglers.forEach((toggler) => {
    const block = toggler.closest('[data-field-block]');
    if (block) {
      toggler.addEventListener('click', (e) => {
        e.preventDefault();
        block.classList.toggle('_open');
      });
    }
  });
}

function getPrepareTimeout() {
  let timeout = 0;
  if (process.env.NODE_ENV !== 'production') {
    timeout = 2000;
  }
  return timeout;
}

function initStickyFilter(form) {
  const navigation = form.querySelector('[data-filter-navigation]');
  const header = document.querySelector('[data-fixed-header]');
  if (!navigation || !header) {
    return;
  }
  const navigationWrapper = navigation.closest('[data-navigation-wrapper]');
  if (!navigationWrapper) {
    return;
  }
  setTimeout(() => {
    document.addEventListener('scroll', () => {
      const offsetWrapper = navigationWrapper.getBoundingClientRect().top;
      const headerHeight = header.getBoundingClientRect().height;
      if (offsetWrapper - headerHeight < 0) {
        navigation.classList.add('_sticky');
      } else {
        navigation.classList.remove('_sticky');
      }
    });
  }, getPrepareTimeout());
}

function initMobileToggler(form) {
  const toggler = form.querySelector('[data-navigation-toggler]');
  const header = document.querySelector('[data-fixed-header]');
  if (toggler) {
    toggler.addEventListener('click', (e) => {
      e.preventDefault();
      form.classList.toggle('_expand');
      if (form.classList.contains('_expand')) {
        document.body.classList.add('_fixed_filter');
        header.classList.add('_background_form');
      } else {
        document.body.classList.remove('_fixed_filter');
        header.classList.remove('_background_form');
      }
    });
  }
}

function initSubmit(form) {
  form.dataset.safeReset = 'false';
  form.addEventListener('submit', (e) => {
    e.preventDefault();
    const jax = new Jax(form.getAttribute('action'), form.getAttribute('method'));
    jax.send(new FormData(form)).then((html) => {
      const page = document.createElement('div');
      page.innerHTML = html;
      const newData = page.querySelector('[data-filter-products]');
      const currentData = document.querySelector('[data-filter-products]');
      currentData.innerHTML = newData.innerHTML;
      form.dispatchEvent(new Event('replace_done'));
    });
  });
  form.addEventListener('replace_done', () => {
    form.classList.remove('_expand');
    document.body.classList.remove('_fixed_filter');
    const submitButton = form.querySelector('[data-submit-button]');
    if (submitButton) {
      submitButton.classList.remove('_wait');
    }
    const header = document.querySelector('[data-fixed-header]');
    if (header) {
      header.classList.remove('_background_filter');
    }
  });
}

function initFields(form) {
  const inputs = form.querySelectorAll('[data-range-from-input], [data-range-to-input], .checkbox-list input');

  const submitForm = _.debounce((formToSubmit) => {
      formToSubmit.dispatchEvent(new Event('submit'));
  }, 2000);

  inputs.forEach((input) => {
    input.addEventListener('change', () => {
      if (window.innerWidth >= 1280) {
        submitForm(form);
      }
    });
  });
}

function initButtons(form) {
  const submitButton = form.querySelector('[data-submit-button]');
  if (submitButton) {
    submitButton.addEventListener('click', (e) => {
      e.preventDefault();
      submitButton.classList.add('_wait');
      form.dispatchEvent(new Event('submit'));
    });
  }

  const resetButtons = form.querySelectorAll('[data-reset-button]');
  if (resetButtons) {
    resetButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        form.reset();
        const ranges = form.querySelectorAll('[data-range-from-input], [data-range-to-input]');
        ranges.forEach((input) => {
          input.dispatchEvent(new Event('hardSet'));
        });
        if (window.innerWidth < 1280) {
          form.dispatchEvent(new Event('submit'));
        }
      });
    });
  }
}


function initFilter() {
  const form = document.querySelector('[data-catalog-filter]');
  if (form) {
    initFieldTogglers(form);
    initStickyFilter(form);
    initMobileToggler(form);
    initSubmit(form);
    initFields(form);
    initButtons(form);
  }
}


document.addEventListener('DOMContentLoaded', initFilter);