const links = document.querySelectorAll('[data-accordion-link]');
links.forEach((link) => {
  link.addEventListener('click', (e) => {
    e.preventDefault();
    const nextElem = link.nextElementSibling;
    const targets = nextElem.querySelectorAll('[data-delivery-tab]');
    targets.forEach((target) => {
      if (!link.classList.contains('_opened')) {
        target.classList.add('_opened');
        link.classList.add('_opened');
      } else {
        target.classList.remove('_opened');
        link.classList.remove('_opened');
      }
    });
  });
});