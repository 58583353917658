class preloader {
  constructor() {
    this.imageList = document.querySelectorAll('[data-load]');
    this.imageCount = this.imageList.length;
    this.container = document.querySelector('[data-preloader]');
    if (this.container && this.imageCount) {
      this.imagesDone = 0;
      this.startPreloader();
    }
  }

  startPreloader() {
    document.body.style.overflow = 'hidden';
    this.bindImages();
    window.addEventListener('load', this.forseEnd);
  }

  bindImages() {
    this.imageList.forEach((image , key ) => {
      const cloneImage = new Image();

      cloneImage.onload = () => {
        this.imagesDone += 1;
        this.refreshState();
      };
      cloneImage.onerror = () => {
        this.imageCount--;
        this.refreshState();
      };
      cloneImage.src = image.src;
    });
  }

  refreshState() {
    if (this.imagesDone === this.imageCount) {
      this.endPreloader();
    }
  }

  endPreloader() {
    this.container.classList.add('_hide');
    document.body.style.overflow = '';
    setTimeout(() => {
      this.container.remove();
      const layout = document.querySelector('.layout_preload');
      if (layout) {
        layout.classList.remove('layout_preload');
      }
    }, 600);
  }

  forseEnd() {
    if (this.container) {
      this.endPreloader();
    }
  }
}

function preloaderInit() {
  const preload = new preloader();
}

window.addEventListener("DOMContentLoaded", preloaderInit);