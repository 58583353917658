function initExpandMenu() {
  const expandLinks = document.querySelectorAll('header [data-expand-link]');

  expandLinks.forEach((link) => {
    const expandBlock = link.closest('[data-expand-block]');

    link.addEventListener('click', (e) => {
      if (window.innerWidth < 1280) {
        e.preventDefault();
        expandBlock.classList.toggle('_expand');

        const alreadyExpand = document.querySelectorAll('[data-expand-block]._expand');
        alreadyExpand.forEach((already) => {
          if (already !== expandBlock) {
            already.classList.remove('_expand');
          }
        });
      }
    });
  });
}

function initHamburgers() {
  const mobileHamburger = document.querySelector('[data-mobile-hamburger]');
  if (mobileHamburger) {
    const header = document.querySelector('[data-fixed-header]');
    const headerBottom = document.querySelector('[data-header-bottom]');
    mobileHamburger.addEventListener('click', (e) => {
      if (headerBottom) {
        headerBottom.classList.toggle('_opened');
      }
      mobileHamburger.classList.toggle('_opened');
      if (mobileHamburger.classList.contains('_opened')) {
        document.body.classList.add('_fixed');
        header.classList.add('_background');
      } else {
        document.body.classList.remove('_fixed');
        header.classList.remove('_background');
      }
    });
  }
}

function initStickyHeader() {
  const header = document.querySelector('[data-fixed-header]');
  if (header) {
    document.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        header.classList.add('_sticky');
      } else {
        header.classList.remove('_sticky');
      }
    });
  }
}

function initHeader() {
  initExpandMenu();
  initHamburgers();
  initStickyHeader();

  const event = new Event('scroll');
  document.dispatchEvent(event);
}


document.addEventListener('DOMContentLoaded', initHeader);