class HeroSlider {
  constructor(element) {
    this.backgroundList = element.querySelector('[data-background-list]');
    this.textList = element.querySelector('[data-text-list]');
    this.slides = [];
    this.currentSlide = 0;
    this.speed = 5000;
    this.order = [];
    this.progressBlocked = false;

    const slides = this.textList.querySelectorAll('[data-text-item]');
    slides.forEach((slide) => {
      const slideId = parseInt(slide.dataset.textItem, 10);
      this.slides[slideId] = {
        id: slideId,
        block: slide,
        progressBar: slide.querySelector('[data-progress-bar]'),
        progressElement: slide.querySelector('[data-progress]'),
        imageItem: this.backgroundList.querySelector(`[data-background-item="${slideId}"]`),
      };
    });

    this.initOrder();
    this.bind();
    this.start();
  }

  bind() {
    this.bindLinks();
    // this.bindHovers();
  }

  bindLinks() {
    const me = this;
    this.slides.forEach((slide) => {
      const link = slide.block.querySelector('[data-hero-link]');
      link.addEventListener('click', (e) => {
        e.preventDefault();
        if (slide.id !== me.currentSlide){
          me.endProgress(me.currentSlide);
          me.changeSlide(me.currentSlide, slide.id);
        }
      });
    });
  }

  bindHovers() {
    this.textList.addEventListener('mouseover', () => {
      this.progressBlocked = true;
    });

    this.textList.addEventListener('mouseout', () => {
      this.progressBlocked = false;
    });
  }

  initOrder() {
    let firstId = 0;
    let prevId = 0;
    this.slides.forEach((slide) => {
      if (!firstId) {
        firstId = slide.id;
      }
      if (prevId) {
        this.order[prevId] = slide.id;
      }
      prevId = slide.id;
    });
    this.order[prevId] = firstId;
    this.currentSlide = firstId;
  }

  start() {
    setTimeout(() => {
      this.slides[this.currentSlide].block.classList.add('_active');
      this.slides[this.currentSlide].imageItem.classList.add('_active');
      this.startProgress(this.currentSlide);
    }, 100);
  }

  startProgress(slideId) {
    const me = this;
    const progressElement = this.slides[slideId].progressElement;
    this.progress = 0;
    progressElement.style.width = 0;

    this.interval = setInterval(() => {
      if (!this.progressBlocked) {
        me.progress += 1;
        progressElement.style.width = `${me.progress * 1.1}%`;
      }
      if (me.progress >= 100) {
        me.endProgress(slideId);
        me.changeSlide(slideId, me.order[slideId]);
      }
    }, this.speed / 100);
  }

  endProgress(slideId) {
    const progressElement = this.slides[slideId].progressElement;

    clearInterval(this.interval);
    this.progress = 0;
    progressElement.style.width = '0%';
  }

  changeSlide(slideIdFrom, slideIdTo) {
    this.slides[slideIdFrom].imageItem.classList.add('_fade-out');
    setTimeout(() => {
      this.slides[slideIdFrom].imageItem.classList.remove('_fade-out');
    }, 1000);

    this.slides[slideIdFrom].block.classList.remove('_active');
    this.slides[slideIdFrom].imageItem.classList.remove('_active');
    this.slides[slideIdTo].block.classList.add('_active');
    this.slides[slideIdTo].imageItem.classList.add('_active');
    this.currentSlide = slideIdTo;
    this.startProgress(slideIdTo);
  }
}

function initHeroSlider() {
  const heroElement = document.querySelector('[data-hero-slider]');
  if (heroElement) {
    const heroSlider = new HeroSlider(heroElement);
  }
}

document.addEventListener('DOMContentLoaded', initHeroSlider);