require('../scss/app.scss');

window.noZensmooth = true;

require('fslightbox');
require('./lightbox/video.js');


require('./common/true-vh.js');
require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/map.js');
require('./accordion');
require('./common/need-animation.js');
require('./common/floating-field.js');
require('./slider/slider.js');
require('./slider/hero-slider.js');
require('./index-map.js');

require('./field/phone.js');
require('./field/range.js');
require('../components/smart-tabs/smart-tabs');

require('../fonts/Montserrat/stylesheet.css');
require('./filterForm/filterForm');

require('./header/header.js');
require('./catalog/filter.js');

require('./links/sticky.js');
require('./preloader/preloader');