import noUiSlider from "nouislider";
import wNumb from "wnumb";
import _ from "lodash";
import css from "nouislider/distribute/nouislider.css";

function initRangeInputSliers() {
  const sliderContainers = document.querySelectorAll('[data-range-input]:not([data-slider-activate])');
  sliderContainers.forEach((container) => {
    const rangeInputSliderInstance = new rangeInputSlider(container);
  });
}

class rangeInputSlider {
  constructor(container, options) {
    container.dataset.sliderActivate = true;
    this.container = container;
    this.init(options);
  }

  init(options) {
    this.fromInput = this.container.querySelector('[data-range-from-input]');
    this.toInput = this.container.querySelector('[data-range-to-input]');
    this.fromShow = this.container.querySelector('[data-range-from-show]');
    this.toShow = this.container.querySelector('[data-range-to-show]');
    this.line = this.container.querySelector('[data-range-line]');
    this.options = {
      step: 1,
      min: 0,
      max: 10,
      postfix: '',
      from: 1,
      to: 10,
    };

    this.options = Object.assign(this.options, this.container.dataset);

    if (options) {
      this.options = Object.assign(this.options, options);
    }

    this.slider = noUiSlider.create(this.line, {
      step: parseInt(this.options.step, 10),
      range: {
        min: parseInt(this.options.min ? this.options.min : 0, 10),
        max: parseInt(this.options.max ? this.options.max : 1, 10),
      },
      connect: true,
      start: [
        parseInt(this.options.from, 10),
        parseInt(this.options.to, 10),
      ],
      format: wNumb({
        decimals: 0,
        thousand: ' ',
        suffix: this.options.postfix,
      }),
    });
    this.bind();
  }

  bind() {
    const instance = this;
    this.slider.on('update', (values, handle) => {
      instance.fromInput.value = values[0];
      instance.toInput.value = values[1];
      instance.fromShow.innerHTML = values[0];
      instance.toShow.innerHTML = values[1];
      const event = new Event('change');
      instance.fromInput.dispatchEvent(event);
      instance.toInput.dispatchEvent(event);
    });

    const hardSetter = () => {
      instance.slider.set([
        instance.fromInput.value,
        instance.toInput.value,
      ]);
    };

    const setter = _.debounce(() => {
      hardSetter();
    }, 2000);

    this.fromInput.addEventListener('keyup', setter);
    this.toInput.addEventListener('keyup', setter);

    this.fromInput.addEventListener('hardSet', hardSetter);
    this.toInput.addEventListener('hardSet', hardSetter);
  }
}

document.addEventListener('DOMContentLoaded', initRangeInputSliers);