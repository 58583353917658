// Выводит в консоль координаты для удобного заполнения

const mapWrapper = document.querySelector('.map__wrapper');
if (mapWrapper) {
  mapWrapper.addEventListener('click', (e) => {
    const rect = mapWrapper.getBoundingClientRect();

    const x = e.clientX - rect.x;
    const y = e.clientY - rect.y;

    const percentX = Math.round(10000 * x / rect.width) / 100;
    const percentY = Math.round(10000 * y / rect.height) / 100;

    console.log('X = ' + percentX);
    console.log('Y = ' + percentY);
  });
}