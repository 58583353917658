class StickyTabs {
  constructor(htmlElement, options) {
    this.container = htmlElement;
    this.options = {
      top: 55,
    };
    this.options = Object.assign({}, this.options, options);

    this.init();
  }

  init() {
    this.contents = [];
    this.parent = this.container.parentElement;
    this.links = this.container.querySelectorAll('a');
    this.links.forEach((link, index) => {
      const selector = link.getAttribute('href');
      const contentElement = document.querySelector(selector);
      contentElement.dataset.chainedLink = `${index}`;
      this.contents.push(contentElement);
      if (link.classList.contains('_active')) {
        this.currentLink = link;
        this.currentContent = contentElement;
      }
    });

    this.bind();
  }

  bind() {
    document.addEventListener('scroll', () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const scrolled = scrollTop + windowHeight;
      const offsetWrapper = this.parent.getBoundingClientRect().top;
      console.log(offsetWrapper)
      if (offsetWrapper - this.options.top < 0) {
        this.container.classList.add('_sticky');
      } else {
        this.container.classList.remove('_sticky');
      }

      const currentTop = this.currentContent.getBoundingClientRect().top + scrollTop;
      this.contents.forEach((content) => {
        const bounds = content.getBoundingClientRect();
        const offsetTop = bounds.top + scrollTop;
        if (content === this.currentContent) {
          return;
        }
        if (scrolled > offsetTop && offsetTop > currentTop) {
          this.change(content);
        } else if (currentTop > scrolled && offsetTop + bounds.height < scrolled) {
          this.change(content);
        }
      });
    });
  }

  change(content) {
    this.currentContent = content;
    this.currentLink.classList.remove('_active');
    this.currentLink = this.links[content.dataset.chainedLink];
    this.currentLink.classList.add('_active');
  }
}

function initStickyLinks() {
  const containers = document.querySelectorAll('[data-sticky-links]');
  containers.forEach((container) => {
    const stickyContainer = new StickyTabs(container);
  });
}
document.addEventListener('DOMContentLoaded', initStickyLinks);