import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

function initSliders() {
  const actionSlider = new MyTinySlider({
    sliderContainer: '[data-action-slider-container]',
    sliderList: '[data-action-slider]',
    itemsCount: 3,
    slideBy: 1,
    nav: false,
    speed: 700,
    controls: false,
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    loop: false,
    swipeAngle: false,
    rewind: false,
    arrowLeft: '[data-action-left]',
    arrowRight: '[data-action-right]',
    responsive: {
      375: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1280: {
        items: 3,
      },
    },
  });

  const advantageSlider = new MyTinySlider({
    sliderContainer: '[data-middle-slider-container]',
    sliderList: '[data-middle-slider]',
    itemsCount: 1,
    slideBy: 1,
    nav: false,
    speed: 2000,
    controls: false,
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    loop: false,
    swipeAngle: false,
    rewind: false,
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    animateIn: 'fade-in',
    animateOut: 'fade-out',
    mode: 'gallery',
  });

  const thumbsSlider = new MyTinySlider({
    sliderContainer: '[data-thumbs-slider-container]',
    sliderList: '[data-thumbs-slider]',
    itemsCount: 1,
    slideBy: 1,
    nav: true,
    speed: 500,
    controls: true,
    loop: false,
    swipeAngle: false,
    autoplay: true,
    rewind: false,
    arrowLeft: false,
    arrowRight: false,
    slideIndex: null,
    slideCount: null,
    navContainer: '[data-thumb-slider]',
    navAsThumbnails: true,
  });

  setTimeout(() => {

    if (document.querySelector('[data-thumb-slider]')) {
      const slider = new MyTinySlider({
        sliderContainer: '[data-thumb-slider-container]',
        sliderList: '[data-thumb-slider]',
        slideBy: 1,
        nav: false,
        vertical: true,
        speed: 700,
        controls: false,
        loop: false,
        swipeAngle: false,
        rewind: true,
        slideIndex: null,
        slideCount: null,
        arrowLeft: '[data-slider-left]',
        arrowRight: '[data-slider-right]',
        itemsCount: 4,
        verticalTable: true,
        responsive: {
          375: {
          },
          768: {
            itemsCount: 4,
            gutter: 10,
          },
          1280: {
            itemsCount: 4,
            gutter: 10,
          },
        },
      });
    }
  }, 500);

  const reviewSlider = new MyTinySlider({
    sliderContainer: '[data-review-slider-container]',
    sliderList: '[data-review-slider]',
    itemsCount: 1,
    slideBy: 1,
    nav: false,
    speed: 500,
    controls: false,
    loop: true,
    swipeAngle: false,
    rewind: false,
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
  });


  const equipmentSlider = new MyTinySlider({
    sliderContainer: '[data-equipments-slider-container]',
    sliderList: '[data-equipments-slider]',
    itemsCount: 1,
    slideBy: 1,
    nav: false,
    gutter: 1,
    speed: 500,
    controls: false,
    loop: false,
    swipeAngle: false,
    rewind: false,
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    autoWidth: window.innerWidth <= 768,
    responsive: {
      375: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1280: {
        items: 4,
      },
    },
  });

  const smallSlider = new MyTinySlider({
    sliderContainer: '[data-small-slider-container]',
    sliderList: '[data-small-slider]',
    itemsCount: 1,
    slideBy: 1,
    nav: true,
    speed: 500,
    autoplay: true,
    controls: false,
    loop: false,
    swipeAngle: false,
    autoHeight: false,
    rewind: true,
  });
  if (smallSlider.slider) {
    smallSlider.slider.events.on('transitionStart', (info) => {
      const container = info.container.closest('[data-small-slider-container]');
      const currentImage = container.querySelector('[data-background-item]._active');
      const nextImage = container.querySelector(`[data-background-item="${info.index}"]`);
      if (currentImage) {
        currentImage.classList.add('_fade-out');
        setTimeout(() => {
          currentImage.classList.remove('_fade-out');
        }, 1000);
        currentImage.classList.remove('_active');
      }
      if (nextImage) {
        nextImage.classList.add('_active');
      }
    });
  }

  const buySlider = new MyTinySlider({
    sliderContainer: '[data-buy-slider-container]',
    sliderList: '[data-buy-slider]',
    itemsCount: 3,
    slideBy: 1,
    gutter: 15,
    nav: false,
    speed: 500,
    controls: false,
    slideIndex: '[data-current-index]',
    slideCount: '[data-current-count]',
    loop: false,
    swipeAngle: false,
    rewind: false,
    arrowLeft: '[data-slider-left]',
    arrowRight: '[data-slider-right]',
    autoWidth: window.innerWidth <= 1280,
    responsive: {
      375: {
        itemsCount: 1,
      },
      768: {
        itemsCount: 1,
      },
      1280: {
        itemsCount: 3,
      },
    },
  });
}

document.addEventListener('DOMContentLoaded', initSliders);
document.addEventListener('DOMContentMutated', initSliders);

class MyTinySlider {
  constructor(options) {
    this.options = {
      sliderContainer: '',
      sliderList: '',
      itemsCount: 1,
      slideIndex: '[data-slider-index]',
      slideCount: '[data-slider-count]',
      arrowLeft: '[data-slider-prev]',
      arrowRight: '[data-slider-next]',
      sliderInitialization: 'data-slider-initialization',
      slider: null,
      disableTabletDown: false,
      tabletWidth: 1280,
      mode: 'carousel',
      animateIn: 'tns-fadeIn',
      animateOut: 'tns-fadeOut',
      hideShowArrows: false,
      autoHeight: false,
      edgePadding: 0,
      gutter: 0,
      autoWidth: false,
      fixedWidth: false,
      verticalTable: false,
    };
    this.options = Object.assign({}, this.options, options);

    this.init();
  }

  init() {
    const sliderContainers = document.querySelectorAll(this.options.sliderContainer);
    if (sliderContainers) {
      sliderContainers.forEach((sliderContainer) => {
        if (sliderContainer.dataset.sliderInitialization) {
          return true;
        }
        sliderContainer.dataset.sliderInitialization = '1';

        const sliderList = sliderContainer.querySelector(this.options.sliderList);

        if (sliderList) {
          this.initSlider(sliderContainer, sliderList);
        }

        return true;
      });
    }
  }

  initSlider(sliderContainer, sliderList) {
    const options = {
      container: sliderList,
      controls: false,
      nav: this.options.nav,
      items: this.options.itemsCount,
      speed: this.options.speed,
      autoplay: false,
      autoplayButtonOutput: false,
      mode: this.options.mode,
      animateIn: this.options.animateIn,
      animateOut: this.options.animateOut,
      autoHeight: this.options.autoHeight,
      edgePadding: this.options.edgePadding,
      gutter: this.options.gutter,
      autoWidth: this.options.autoWidth,
      fixedWidth: this.options.fixedWidth,
      responsive: {},
    };

    /* this.setParentHeight(sliderContainer); */
    options.loop = true;
    if (this.options.loop === false) {
      options.loop = false;
    }

    if (this.options.responsive) {
      options.responsive = Object.assign(options.responsive, this.options.responsive);
    }

    const largePoint = 1268;
    const mediumPoint = 768;
    if (this.options.verticalTable && window.innerWidth >= mediumPoint && window.innerWidth < largePoint) {
      options.axis = 'vertical';
    }

    if (this.options.navContainer) {
      const navContainer = document.querySelector(this.options.navContainer);
      if (navContainer) {
        options.navContainer = navContainer;
        options.navAsThumbnails = true;
      }
    }
    const slider = tns(options);

    sliderContainer.setAttribute(this.options.sliderInitialization, 'true');
    this.bindArrows(sliderContainer, slider);
    this.initSliderCounter(sliderContainer, slider);
    this.slider = slider;
  }

  initSliderCounter(sliderContainer, slider) {
    if (slider && this.options.slideIndex && this.options.slideCount) {
      const slidesIndexText = sliderContainer.querySelector(this.options.slideIndex);
      const slidesCountText = sliderContainer.querySelector(this.options.slideCount);
      let info = slider.getInfo();
      let slidesIndex = info.displayIndex;
      let slidesCount = info.slideCount;

      if (slidesIndexText && slidesCountText) {
        slidesIndexText.innerHTML = slidesIndex;
        slidesCountText.innerHTML = slidesCount;

        slider.events.on('transitionStart', () => {
          info = slider.getInfo();
          slidesIndex = info.displayIndex;
          slidesCount = info.slideCount;
          slidesIndexText.innerHTML = slidesIndex;
          slidesCountText.innerHTML = slidesCount;
        });
      }
    }
  }

  bindArrows(sliderContainer, slider) {

    const prevArrow = sliderContainer.querySelector(this.options.arrowLeft);

    if (prevArrow) {
      prevArrow.addEventListener('click', () => {
        slider.goTo('prev');
      });
    }


    const nextArrow = sliderContainer.querySelector(this.options.arrowRight);

    if (nextArrow) {
      nextArrow.addEventListener('click', () => {
        slider.goTo('next');
      });
    }

    if (this.options.hideShowArrows && prevArrow && nextArrow) {
      slider.events.on('transitionStart', () => {
        const info = slider.getInfo();
        const currentIndex = info.displayIndex;
        const maxIndex = info.slideCount - (info.items - 1);

        prevArrow.classList.remove('_hide');
        nextArrow.classList.remove('_hide');
        if (currentIndex === 1) {
          prevArrow.classList.add('_hide');
        } else if (currentIndex === maxIndex) {
          nextArrow.classList.add('_hide');
        }
      });
    }
  }
}